import { IonButton, IonButtons, IonPopover, IonAlert, IonModal, IonGrid, IonRow, IonCol, IonHeader, IonTitle, IonIcon, IonImg, IonItem, IonList, IonSelect, IonSelectOption, IonTab, IonToolbar, IonContent} from "@ionic/react";
import {searchOutline, person} from 'ionicons/icons';
import { menuController } from '@ionic/core/components';
import './NavBar.css';
import logo from '../resources/logo/logo_transparent_edited.png';
import "@fontsource/abril-fatface";
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';

//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';

const NavBar = (props) => {

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const subscribed = "Is Subscribed"
  const startDate = "2/2/2022"
  const endDate = "2/2/2023"
  const isSubscribed = props.isSubscribed
  const history = useHistory();
  const popoverbase = useRef(null)

    //Language Data
    const resources = {
      en: {
          translation: {
            "Home": "Home",
            "About Us": "About Us",
            "Contact": "Contact",
            "Login": "Login",
            "Subscribe": "Subscribe",
            "Lang": "العربية",
            "Logout": "Logout",
            "Subscription": "Subscription",
            "Close": "Close",
            "Subscription Details": "Subscription Details",
            "Start Date": "Start Date",
            "End Date": "End Date",
            "Cancel Subscription": "Cancel Subscription",
            "Start Subscription": "Start Subscription",
            "Delete Account" : "Delete Account",
            "Change Password" : "Change Password",
            "Confirmation" : "Confirmation",
            "Are you sure?" : "Are you sure?",
            "Subscribed" : "Subscribed",
            "not Subscribed" : "not subscribed"
          },
      },
      ar: {
          translation: {
            "Home": "الصفحة الرئيسية",
            "About Us": "معلومات عنا",
            "Contact": "اتصال",
            "Login": "تسجيل الدخول",
            "Subscribe": "اشترك",
            "Lang": "English",
            "Logout": "تسجيل الخروج",
            "Subscription": "الاشتراك",
            "Close": "أغلق",
            "Subscription Details": "تفاصيل الاشتراك",
            "Start Date": "تاريخ البدء",
            "End Date": "تاريخ الانتهاء",
            "Cancel Subscription": "إلغاء الاشتراك",
            "Start Subscription": "ابدأ الاشتراك",
            "Delete Account" : "حذف الحساب",
            "Change Password" : "تغيير كلمة المرور",
            "Confirmation" : "تأكيد",
            "Are you sure?" : "هل أنت متأكد؟",
            "Subscribed" : "مشترك",
            "not Subscribed" : "غير مشترك"
          },
      },
    };
  
    i18n.use(initReactI18next).init({
      resources,
      lng: localStorage.getItem('language') || props.lang, // Default language
      keySeparator: false,
      interpolation: { escapeValue: false },
    });

  const onClickNav = (name) => {

    switch (name) {
      case 'home':
        window.history.replaceState(null, null, '/');
        window.location.reload();
        break;
      case 'aboutus':
        history.push({
          pathname: '/home/aboutus'
        })
        break;
      case 'contact':
        history.push({
          pathname: '/home/contact'
        })
      default:
        break;
    }

  }
  const viewMenu = async () => {
    await menuController.open('first-menu');
  }


   return(
    <IonToolbar mode="ios" className="navbar_header">
      <IonModal isOpen={isModalOpen}   className="navbar_modal" onDidDismiss={()=>setIsModalOpen(false)} >
        <IonHeader className="navbar_modal_header">
          {props.lang=='en'?
            <IonToolbar className="navbar_modal_toolbar">
              <IonTitle>{t('Subscription')}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsModalOpen(false)}>{t('Close')}</IonButton>
              </IonButtons>
            </IonToolbar>
          : 
            <IonToolbar className="navbar_modal_toolbar">
              <IonButtons >
                <IonButton onClick={() => setIsModalOpen(false)}>{t('Close')}</IonButton>
              </IonButtons>
              <IonTitle slot="end" >{t('Subscription')}</IonTitle>
            </IonToolbar>                  
          }
          
        </IonHeader>
        <IonContent className="ion-padding navbar_modal_content">
          <IonList lines="none" className="navbar_modal_list">
            <IonGrid>
              
                {props.lang=='en'?
                <IonRow>
                  <IonCol>
                  <IonItem className="navbar_modal_item_left">{t('Subscription Details')}</IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem className="navbar_modal_item_right">{isSubscribed? t("Subscribed"): t("not Subscribed")}</IonItem>
                  </IonCol>
                </IonRow>
                :
                <IonRow>
                  <IonCol>
                    <IonItem className="navbar_modal_item_right item_arabic">{isSubscribed? t("Subscribed"): t("not Subscribed")}</IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem className="navbar_modal_item_left item_arabic">{t('Subscription Details')}</IonItem>
                  </IonCol>
                </IonRow>
                }
                
                {props.lang=='en'?
                  <IonRow>
                    <IonCol>
                      <IonItem className="navbar_modal_item_left ">{t('Start Date')}</IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem className="navbar_modal_item_right">{props.subscriptionStart? props.subscriptionStart.toISOString().split('T')[0] : '-'}</IonItem>
                    </IonCol>
                  </IonRow>
                  :
                  <IonRow>
                    <IonCol>
                      <IonItem className="navbar_modal_item_right item_arabic">{props.subscriptionStart? props.subscriptionStart.toISOString().split('T')[0] : '-'}</IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem className="navbar_modal_item_left item_arabic">{t('Start Date')}</IonItem>
                    </IonCol>
                  </IonRow>
                }

                {props.lang=='en'?
                  <IonRow>
                    <IonCol>
                      <IonItem className="navbar_modal_item_left">{t('End Date')}</IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem className="navbar_modal_item_right">{props.subscriptionEnd? props.subscriptionEnd.toISOString().split('T')[0] : '-'}</IonItem>
                    </IonCol>
                  </IonRow>
                :
                <IonRow>
                    <IonCol>
                      <IonItem className="navbar_modal_item_right item_arabic">{props.subscriptionEnd? props.subscriptionEnd.toISOString().split('T')[0] : '-'}</IonItem>
                    </IonCol>
                    <IonCol>
                      <IonItem className="navbar_modal_item_left item_arabic">{t('End Date')}</IonItem>
                    </IonCol>
                  </IonRow>
                }
              {isSubscribed ?
              
              <IonRow className="navbar_modal_row_btn">
                <IonCol className="navbar_modal_col_btn">
                  <IonButton onClick={props.sendUserToPortal} className="navbar_modal_button">{t('Update or Cancel Subscription')}</IonButton>
                </IonCol>
              </IonRow>
            
            : 
            
            <IonRow className="navbar_modal_row_btn">
              <IonCol className="navbar_modal_col_btn">
                <IonButton onClick={()=>{setIsModalOpen(false);history.push('/premium')}} className="navbar_modal_button">{t('Start Subscription')}</IonButton>
              </IonCol>
            </IonRow>
            }
            </IonGrid>
          </IonList>
        </IonContent>
      </IonModal>
      <IonButtons slot="start" className="navbar_btns">
        <IonButton className="navbar_small navbar_btn" onClick={()=>{viewMenu()}}>☰</IonButton>
          <img src={logo} className="logo_img"></img>
          <IonButton className="navbar_big navbar_btn" onClick={()=>{onClickNav('home')}}>
            {t('Home')}
          </IonButton>
          <IonButton className="navbar_big navbar_btn" onClick={()=>{onClickNav('aboutus')}}>
            {t('About Us')}
          </IonButton>
          <IonButton className="navbar_big navbar_btn" onClick={()=>{onClickNav('contact')}}>
            {t('Contact')}
          </IonButton>
          <IonButton className="navbar_btn" onClick={props.onLangClick}>
              {t('Lang')}
            </IonButton>
          {/* <IonButton>
            <IonIcon icon={searchOutline}></IonIcon>
          </IonButton> */}
           
        </IonButtons>
          {props && props.showLogin ? 
          <IonButtons slot="end" >
            <IonButton className="navbar_btn" onClick={props.onLoginClick}>
              {t('Login')}
            </IonButton>
            <IonButton className="navbar_btn" onClick={props.onSubscribeClick}>
              {t('Subscribe')}
            </IonButton>
          </IonButtons>   
          : props && props.showAccount ?
          <IonButtons slot="end" ref={popoverbase} >
              <div>{props.username ? props.username : ''}</div>
              <IonButton id="click-trigger" onClick={() => setIsPopoverOpen(true)} className="navbar_btn"> 
                <IonIcon icon={person}></IonIcon>
              </IonButton>
              <IonPopover className="navbar_pop" mode="md" arrow={false} trigger="click-trigger"  isOpen={isPopoverOpen} onDidDismiss={() => setIsPopoverOpen(false)}    dismissOnSelect={true}>
                  <IonContent className="navbar_pop_content">
                    <IonList className="navbar_list" lines="none">
                      {!props.verified ?
                        <IonItem button={true} onClick={() => props.onVerify()} className={props.lang=='en' ? "navbar_item" : "navbar_item navbar_item_arabic"} >{t('Verify')}</IonItem>
                      :null
                      }
                      <IonItem button={true} onClick={() => setShowAlert(true)} className={props.lang=='en' ? "navbar_item" : "navbar_item navbar_item_arabic"} >{t('Logout')}</IonItem>
                      <IonItem button={true} onClick={() => setIsModalOpen(true)} className={props.lang=='en' ? "navbar_item" : "navbar_item navbar_item_arabic"}  >{t('Subscription')}</IonItem>
                      <IonItem button={true} onClick={() => history.push('/resetpass')} className={props.lang=='en' ? "navbar_item" : "navbar_item navbar_item_arabic"} >{t('Change Password')}</IonItem>
                      {/* <IonItem button={true} className={props.lang=='en' ? "navbar_item" : "navbar_item navbar_item_arabic"} >{t('Delete Account')}</IonItem> */}
                    </IonList>
                  </IonContent>
              </IonPopover>
              <IonAlert isOpen={showAlert} onDidDismiss={() => setShowAlert(false)} header={t('Confirmation')} message={t('Are you sure?')}
                cssClass={props.lang=='en' ? 'navbar_alert' : 'navbar_alert navbar_alert_arabic'}
                mode="md"
                buttons={[
                {
                  text: `${props.lang=='en' ? 'Cancel' : 'إلغاء'}`,
                  role: 'cancel',
                  handler: () => {
                  // Handle cancel action
                    console.log('Cancelled');
                  }
                },
                {
                  text: `${props.lang=='en' ? 'yes' : 'نعم'}`,
                  handler: () => {
                    props.onLogout()
                  }
                }
                ]}
                />

          </IonButtons>
           : null}
        
       
    </IonToolbar>
   )
    

}

export default NavBar;