import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement, Elements, EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

//Axios
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';

import './PaymentForm.css'



const CheckoutForm = (props) => {
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState("")
  const stripe = useStripe();
  const serverIP = "recibits.com"
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);


  const appearance = {
    theme: 'night',
    labels: 'floating'
  };
  useEffect(()=>{


    const getClientPromise = async () => {
      await axios.get(`https://${serverIP}/api/config`, { withCredentials: true })
                .then((result) => {
                    const {publishableKey} = result.data
                    setStripePromise(loadStripe(publishableKey))
                }).finally(()=>{
                  
                }).catch((err) => {
                    
                });
    }

    getClientPromise()
    console.log("done")

  },[])

  useEffect(()=>{


    const getClientSecret = async () => {
      await axios.post(`https://${serverIP}/api/create-payment-intent`, {},{ withCredentials: true })
                .then((result) => {
                    const secretKey = result.data.clientSecret
                    setClientSecret(result.data.clientSecret)
                    console.log(secretKey)
                }).finally(()=>{
                  
                }).catch((err) => {
                    
                });
    }

    const getClientSubscriptionSecret = async (pid) => {
      await axios.post(`https://${serverIP}/api/create-subscription`,{pid},{ withCredentials: true })
      .then((result) => {
          const secretKey = result.data.clientSecret
          setClientSecret(result.data.clientSecret)
          console.log(secretKey)
      }).finally(()=>{
        
      }).catch((err) => {
          console.log(err)
      });
    }

    getClientSubscriptionSecret(props.pid)
    console.log("done")

  },[])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'httpss://your-site.com/checkout-success', // Replace with your success URL
      },
    });

    if (error) setErrorMessage(error.message);
    setIsProcessing(false);
  };

  return (
    <div className='payment-div'>
    {clientSecret && stripePromise ? (
    // <Elements stripe={stripePromise} options={{clientSecret,appearance}}>
    // <form onSubmit={handleSubmit}>
    //   <PaymentElement />
    //   <button type="submit" disabled={!stripe || isProcessing}>
    //     {isProcessing ? 'Processing...' : 'Confirm Subscription'}
    //   </button>
    //   {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    // </form>
    // </Elements>
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{clientSecret}}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
    ):null}
    </div>
  );
};

export default CheckoutForm;
