//#region Imports
//Ionic Components
import { IonContent, IonHeader, IonInput, IonButton, IonPage, useIonAlert, IonSpinner } from "@ionic/react"
import { menuController } from '@ionic/core/components';
//Use React
import { useRef, useState, useEffect} from 'react';
import { useHistory } from 'react-router';
//Components
import NavBar from "../components/NavBar";
import MenuBar from "../components/MenuBar";
//Axios
import axios from 'axios'
//Resources
import logo from '../resources/logo/logo_transparent_edited.png'
//JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
// Style
import './form.css'
//#endregion


const ResetPass = () => {

    //#region Variables
    //Routes
    const history = useHistory();
    //Server IP Address
    const serverIP = "recibits.com";
    //References
    const oldPass = useRef(null); 
    const PassRef = useRef(null);
    const ConPassRef = useRef(null);
    //Input Error
    const [inputError, setInputError] = useState([])
    const rules = [
        { test: (v) => !!v, message: 'PasswordRequired' },
        { test: (v) => !/\s/.test(v), message: 'PasswordSpace' },
        { test: (v) => v.length >= 8, message: 'PasswordLeastCharacter' },
        { test: (v) => /[A-Z]/.test(v), message: 'PasswordCapital' },
        { test: (v) => /[a-z]/.test(v), message: 'PasswordSmall' },
        { test: (v) => /\d/.test(v), message: 'PasswordNumber' },
        // { test: (v) => /[!@#$%^&*(),.?":{}|<>]/.test(v), message: 'PasswordSpecial' },
    ]
    //Process checker
    const [processing, setProcessing] = useState(false)
    //Alert
    const [resetAlert] = useIonAlert();
    //Language Data
    const [lang, setLang] = useState("en")
    const resources = {
        en: {
            translation: {
                "undefined": "",
                //Input Labels
                "Old Password": "Old Password",
                "New Password": "New Password",
                "Confirm Password": "Confirm Password",
                //Input Placeholders
                "Enter Old Password": "Enter Old Password",
                "Enter New Password": "Enter New Password",
                "Re-enter Password": "Re-enter Password",
                //Change password button
                "Change Password": "Change Password",
                //Links
                "Forgot Password": "Forgot Password",
                //Errors
                "PasswordRequired": "Password required!",
                "PasswordSpace": "No spaces allowed.",
                "PasswordLeastCharacter": "Must be at least 8 characters.",
                "PasswordCapital": "Must include at least one uppercase letter.",
                "PasswordSmall": "Must include at least one lowercase letter.",
                "PasswordNumber": "Must include at least one number.",
                "PasswordSpecial": "Must include at least one special character.",
            },
        },
        ar: {
            translation: {
                "undefined": "",
                //Input Labels
                "Old Password": "كلمة المرور القديمة",
                "New Password": "كلمة المرور الجديدة",
                "Confirm Password": "تأكيد كلمة المرور",
                //Input Placeholders
                "Enter Old Password": "أدخل كلمة المرور القديمة",
                "Enter New Password": "أدخل كلمة المرور الجديدة",
                "Re-enter Password": "أعد إدخال كلمة المرور",
                //Change password button
                "Change Password": "تغيير كلمة المرور",
                //Links
                "Forgot Password": "نسيت كلمة السر",
                //Errors
                "PasswordRequired": "كلمة المرور مطلوبة!",
                "PasswordSpace": "لا مسافات مسموح بها.",
                "PasswordLeastCharacter": "يجب أن يكون على الأقل ٨ أحرف.",
                "PasswordCapital": "يجب أن تتضمن حرفًا كبيرًا واحدًا على الأقل.",
                "PasswordSmall": "يجب أن تتضمن حرفًا صغيرًا واحدًا على الأقل.",
                "PasswordNumber": "يجب أن تتضمن رقمًا واحدًا على الأقل.",
                "PasswordSpecial": "يجب أن تتضمن حرفًا خاصًا واحدًا على الأقل.",
            },
        },
    };
    i18n.use(initReactI18next).init({
        resources,
        lng: localStorage.getItem('language') || lang, // Default language
        keySeparator: false,
        interpolation: { escapeValue: false },
    });
    //#endregion

    //#region Functions
    //Language Set From Cookies
    const onLangClick = () => {
        if(lang == "en"){
          setLang("ar")
          Cookies.set('language', "ar", { expires: 365, path: '/' });
        }
        else{
          setLang("en")
          Cookies.set('language', "en", { expires: 365, path: '/' });
        } 
    }
    //Validate Inputs
    const validatePassword = (input) => {
        const failedRules = rules.filter((rule) => !rule.test(input)).map((rule) => rule.message);
        setInputError(failedRules);
    }
    //Reset Password Process
    const ResetPassword = async (pass, cPass, oldpass) => {
        if(pass==''){
            resetAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'Please Enter a Password to proceed!':'الرجاء إدخال كلمة المرور للمتابعة!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            validatePassword('')
            return
        }
        if(inputError.length>0){
            resetAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'The Password you typed is not acceptable!':'كلمة المرور التي كتبتها غير مقبولة!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            return
        }
        //Start Reset password process
        setProcessing(true)
        if(pass == cPass){
            const result = {
                newPassword: pass,
                oldPassword: oldpass
            }
            await axios.put(`https://${serverIP}/api/resetPassword`,result,{withCredentials: true})
            .then(response => {
                if(response.data == 'incorrect'){
                    resetAlert({
                        header: lang=='en'?"Alert":"تنبيه",
                        message: lang=='en'?'Incorrect password!':'كلمة المرور غير صحيحة!',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })
                    return
                }else if(response.data == 'done'){
                    resetAlert({
                        header: lang=='en'?'Done!':"!انتهى",
                        message: lang=='en'?'Password changed!':'تم تغيير كلمة المرور!',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })
                    history.replace('/')
                }else{
                    resetAlert({
                        header: lang=='en'?"ُError!":"!خطأ",
                        message: lang=='en'?'An error occured! Please try again.':'حدث خطأ! يرجى المحاولة مرة أخرى.',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })
                }                
            }).catch(async error => {
                if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                    const trial = await fetchCsrfToken(); // Refresh the token
                    if(!trial){
                    resetAlert({
                        header: lang=='en'?"ُError!":"!خطأ",
                        message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })  
                    }else{
                        // Retry Reset Password
                        ResetPassword(pass, cPass, oldpass);
                    }
                }else{
                    resetAlert({
                        header: lang=='en'?"ُError!":"!خطأ",
                        message: lang=='en'?'An error occured! Please try again.':'حدث خطأ! يرجى المحاولة مرة أخرى.',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })
                }
            })
        }else{
            resetAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?"The Passwords you typed don't match":'كلمات المرور التي كتبتها غير متطابقة',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
        }
        setProcessing(false)
    }
    //CSRF server Request
    const fetchCsrfToken = async () => {
        try {
        await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true}).then(()=>{
            return 1
        });
        } catch (error) {
        //Error Handling
        return 0
        }
    };
    //#endregion

    //#region Use Effect
    useEffect(()=> {
        //Access Token
        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                    // console.log('Access token refreshed');
                })
                .catch(error => {
                    // console.error('Error refreshing token:', error);
                });
        }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        //Access Token Refresh
        const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
            .then(response => {
                //Response
            })
            .catch(error => {
                //Error handling
            });
        }

        //Check cookies for log in info and save the id
        const getProtectedResource = async () => {
            try {
            await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(()=>{
                //Response
            });
            } catch (error) {
                if (error.response && error.response.status === 401) { // Auth Cookie not found
                    try {
                        await axios.post(`https://${serverIP}/api/refresh`, {}, { withCredentials: true  });
                        await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(()=>{
                            //Response
                        });
                    } catch (refreshError) { // No refresh cookie found
                        resetAlert({
                            header: 'Alert!',
                            message: "You have to be logged in!",
                            buttons: ['Ok'],
                            cssClass: 'form_alert',
                            mode: 'md',
                        })
                        history.replace('/login')
                    }
                }
            }
        };

        const loadData = async () => {
            setLang(Cookies.get('language') || 'en')
            await fetchCsrfToken()
            await authenticateAPI()
            await getProtectedResource()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };
    },[])       
    //#endregion
    
    return(

        <IonPage id="main-content">
            <IonHeader className='form_header'>
                <NavBar  lang={lang} onLangClick={onLangClick}></NavBar>
            </IonHeader>
            <IonContent>
                <div className='form_background'></div>
                <div className='form_background_img'></div>
                <div className="form_main">
                    <div className="form">
                        <img src={logo} className="form_logo"></img>
                        <IonInput 
                            mode="md" 
                            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
                            className=" ion-invalid ion-touched form_input"
                            ref ={oldPass} 
                            label={t('Old Password')} 
                            type="password" 
                            labelPlacement="floating" 
                            fill ="outline" 
                            placeholder={t('Enter Old Password')}
                        />
                        <IonInput 
                            mode="md" 
                            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
                            className=" ion-invalid ion-touched form_input " 
                            ref ={PassRef} 
                            label={t('New Password')} 
                            type="password" 
                            labelPlacement="floating" 
                            fill="outline" 
                            placeholder={t('Enter New Password')}
                            helperText="Enter Your New Password"
                            errorText={t(`${inputError[0]}`)}
                            onIonInput={(e)=>validatePassword(e.detail.value || '')}
                        />
                        <IonInput 
                            mode="md" 
                            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
                            className=" ion-invalid ion-touched form_input " 
                            ref ={ConPassRef} 
                            label={t('Confirm Password')} 
                            type="password" 
                            labelPlacement="floating" 
                            fill="outline" 
                            placeholder={t('Re-enter Password')}
                        />
                        {!processing ?
                            <IonButton mode="md" className="form_btn" onClick = {() => ResetPassword(PassRef.current.value, ConPassRef.current.value, oldPass.current.value)}>{t('Reset Password')}</IonButton>
                            :
                            <IonButton mode="md" className="form_btn"><IonSpinner name='circular' className='form_spinner'/></IonButton>
                        }
                        <div className="reset_tags">
                            <a className="reset_tag" href="/forgetPass">{t('Forgot Password')}</a>
                        </div>
                    </div>

                </div>
            </IonContent>
        </IonPage>

    )
}



export default ResetPass;