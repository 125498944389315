//#region Imports
//Import Ionic Components
import { IonButton, IonContent, IonHeader, IonSegment, IonPage, IonLabel, IonSegmentButton, useIonAlert, IonGrid, IonRow, IonCol } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
import PaymentForm from '../components/PaymentForm';
//Import React Components
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
//Import Axios
import axios from "axios";
//Import Style
import './Premium.css';
import SubscriptionButton from "../components/SubscribeButton";
//#endregion

//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';

// import bg from '../resources/marble.png'

const Premium = () => {



const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
    }
    else{
      setLang("en")
    } 
  }

    //#region Variables
    const [view, setView] = useState('monthly');
    const location = useLocation();
    const receivedData = location.state && location.state.data;
    const history = useHistory();
    const [personId, setPersonId] = useState(null)
    const [premiumAlert] = useIonAlert()
    const [isProcessing, setIsProcessing] = useState(false)
    const [chosenPlan, setChosenPlan] = useState(null)
    const [plans, setPlans] = useState([])
    const serverIP = "recibits.com"
    const [error, setError] = useState(null)


    //User Info
    //is logged in?
    const [isLoggedin, setisLoggedin] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    //is premium?
    const [isPremium, setUserPremium] = useState(false)
    const [premiumType, setPremiumType] = useState(null)
    //is verified?
    const [verified, setVerified] = useState(false)
    //username
    const [username, setUsername] = useState('')
    //email
    const [userEmail, setUserEmail] = useState('')

    //Language Data
    const [lang, setLang] = useState("en")
    const resources = {
    en: {
        translation: {
            "Buy Our Premium Plans": "Buy Our Premium Plans",
            "Monthly": "Monthly",
            "Yearly": "Yearly",
            "• Ability to save your favorite Recipe": "• Ability to save your favorite Recipe",
            "• Access to Premium Recipes": "• Access to Premium Recipes",
            "Basic Plan": "Basic Plan",
            "Subscribe": "Subscribe",
            "Premium Plan": "Premium Plan",
            "• Coming soon....": "• Coming soon....",

        },
    },
    ar: {
        translation: {
            "Buy Our Premium Plans": "اشترِ خططنا المميزة",
            "Monthly": "شهريا",
            "Yearly": "سنوي",
            "• Ability to save your favorite Recipe": "• القدرة على حفظ الوصفة المفضلة لديك",
            "• Access to Premium Recipes": "• الوصول إلى وصفات مميزة",
            "Basic Plan": "الخطة الأساسية",
            "Subscribe": "اشترك",
            "Premium Plan": "الخطة المميزة",
            "• Coming soon....": "• قريباً....",
    },
},
    };
    i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
    });
    
    
    // Load your publishable key
    const stripePromise = loadStripe("pk_test_51QFdhRQJNg0lHSmdiuooH70TIDb56FACk2HNRM7SixqXgF8MDW6fycIvRMSSkYmfkHNUkasFzjyhWZ1isVmjHQvO00ahX8kLKf");
    const options = {
        // passing the client secret obtained from the server
        // clientSecret: '{{CLIENT_SECRET}}',
    };
    //#endregion

    //#region Functions
    const handleSegmentChange = (event) => {
        setView(event.detail.value);
    };

    const subscribeUser = (plan) => {
        if (!isLoggedin){
            premiumAlert({
                header: 'Alert!',
                message: "You have to be logged in!",
                buttons: ['Ok'],
                cssClass: 'form_alert',
                mode: 'md',
            })
            history.replace({
                pathname: '/login',
              })
        } else{
            if(!verified){
                premiumAlert({
                    header: 'Alert!',
                    message: "You have to verify your account first! Please do so in the home page.",
                    buttons: ['Ok'],
                    cssClass: 'form_alert',
                    mode: 'md',
                })
                return
            }
            setChosenPlan(plan.id)
            setIsProcessing(true)
        }
    }  

    //Check if the logged in user is subscribed
    const getPlans = async () => {
        await axios.get(`https://${serverIP}/api/plans`,{ withCredentials: true })
                .then((result) => {
                    console.log(result)
                    if(result.data == 'no data'){
                        return
                    }
                    if(result.data)
                        setPlans(result.data)
                }).catch((err) => {
                    setError(err)
                });
    }
    //#endregion

    //#region Use Effect
    useEffect(()=>{

        
        const fetchCsrfToken = async () => {
            try {
                await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true});
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                // console.log('Access token refreshed');
                })
                .catch(error => {
                // console.error('Error refreshing token:', error);
            });
        }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        
        const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
                .then(response => {
                    console.log('Tokens set');
                })
                .catch(error => {
                    console.error('Error setting tokens:', error);
            });
        }

        //Check cookies for log in info and save the id
        const getProtectedResource = async () => {
            try {
            await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(async ()=>{
                setisLoggedin(true)
                setShowAccount(true)
                await axios.get(`https://${serverIP}/api/getUserInfo`, { withCredentials: true }).then((info)=>{
                  if(info.data == 'no data'){
                    return
                  }
                  setUserPremium(info.data.isPremium)
                  setUsername(info.data.username)
                  setUserEmail(info.data.email)
                  setVerified(info.data.verified)
                  setPremiumType(info.data.plan)
                });
            });
            } catch (error) {
                if (error.response && error.response.status === 401) { // Auth Cookie not found
                    try {
                        await axios.post(`https://${serverIP}/api/refresh`, {}, { withCredentials: true  });
                        await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(async ()=>{
                            setisLoggedin(true)
                            setShowAccount(true)
                            await axios.get(`https://${serverIP}/api/getUserInfo`, { withCredentials: true }).then((info)=>{
                            if(info.data == 'no data'){
                                return
                            }
                            setUserPremium(info.data.isPremium)
                            setUsername(info.data.username)
                            setUserEmail(info.data.email)
                            setVerified(info.data.verified)
                            setPremiumType(info.data.plan)
                            });
                        });
                    } catch (refreshError) { // No refresh cookie found
                        
                    }
                }
            }
        };

        
      
        const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
            await getPlans()
            await getProtectedResource()
            
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };

  
      },[])
    //#endregion

return(
    <IonPage>
      <IonHeader class='premium_header'>          
          <NavBar lang={lang} onLangClick={onLangClick}></NavBar>
      </IonHeader>
      <div className='premium_background'></div>
      <IonContent className="premium_content">
        
        <div className='premium_background_img'></div>

        { isProcessing ? (
            <>
            <div style={{position:'absolute',top:'50',left:'50'}}><IonButton className="plan_subscribe" onClick={()=>{setIsProcessing(false)}}>Back</IonButton></div>
         <Elements stripe={stripePromise} options={options}>
         <div className="paymentForm">
             <PaymentForm pid={chosenPlan}/>
         </div>
         </Elements>
         </>
        ):(
        <div className="premium_">
            <h2 className="mainTitle">{t('Buy Our Premium Plans')}</h2> <br></br> <br></br>
            <IonSegment  color="tertiary" value={view} onIonChange={handleSegmentChange} className="segments">
                <IonSegmentButton className="segment_btn"   value="monthly">
                <IonLabel  className="segment_label">{t('Monthly')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton className="segment_btn" value="yearly">
                <IonLabel className="segment_label">{t('Yearly')}</IonLabel>
                </IonSegmentButton>
            </IonSegment>
            <IonGrid className="body_div">
{/* className="body_div" */}
                <IonRow style={{height:'fit-content', display:'flex', justifyContent:"center"}}>
                {
                plans.map((plan,i)=>{if(plan.billing!=view){return null}return(    
                    <IonCol size="12" sizeMd="6" sizeLg="4" key={i} >      
                    <div className="plan_div" >
                
                    <div className="plan_name_div"><div className="flap_background"></div><div className="plan_name">{lang=='en' ? plan.title : plan.titleAr}</div> <div className="plan_closed">•</div></div>

                    <div className="plan_items" >
                        {
                            lang=='en'?
                                plan.plan_points.map((point,d)=>{return(
                                    <div className="plan_item" key={d} style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>• {point}</div>
                                )})
                            :
                            plan.plan_pointsAr.map((point,d)=>{return(
                                <div className="plan_item" key={d} style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>• {point}</div>
                            )})
                        }
                    </div>

                    <div className="plan_price"><span className="price_value">{plan.amount} £</span> {view == "monthly" ? '/month' : '/year'}</div>     
                    <IonButton className="plan_subscribe" mode="md" onClick={()=>subscribeUser(plan)}> {t('Subscribe')} </IonButton>

                    </div>        
                    </IonCol> 
                )})
                }
            {/* <IonCol size="12" sizeMd="6" sizeLg="4" > 
            <div className="plan_div">
                
                <div className="plan_name_div"><div className="flap_background"></div><div className="plan_name">{t('Premium Plan')}</div> <div className="plan_closed">•</div></div>

                <div className="plan_items">
                    <div className="plan_item" style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>{t('• Coming soon....')}</div>

                </div>
                
                <div className="plan_price"><span className="price_value">{view == "monthly" ? '$12' : '$130'}</span>{view == "monthly" ? '/month' : '/year'}</div>     
                <IonButton className="plan_subscribe" color="secondary" onClick={subscribeUser}> {t('Subscribe')} </IonButton>

            </div>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="4" > 
            <div className="plan_div">
            
                <div className="plan_name_div"><div className="flap_background"></div><div className="plan_name">Ultimate Plan</div> <div className="plan_closed">•</div></div>

                <div className="plan_items">
                    <div className="plan_item">• Coming soon....</div>

                </div>
                
                <div className="plan_price"><span className="price_value">{view == "monthly" ? '$17' : '$180'}</span>{view == "monthly" ? '/month' : '/year'}</div>     
                <IonButton color="secondary"> Subscribe </IonButton>

            </div>
            </IonCol> */}
            </IonRow>
                
                
                
            </IonGrid>
            {/* <div className="body-div">

            </div> */}

            



        </div>
        )}

        
            
            <Footer lang={lang}></Footer>

            </IonContent>
   
      </IonPage>
)

}

export default Premium;